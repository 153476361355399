const config = require('../config');

//this constant file is used for both server and client sides, don't change it to ES6 syntaxes
const defaultLocale = 'en';

const ES_SHORT_CODE_LOCALE = 'es';

const LOCALES = {
  ENGLISH: 'en',
  SPANISH_MX: 'es-mx',
  PORTUGUESE_BR: 'pt-br',
  JAPANESE: 'ja'
};

//es-mx is presenting all `es` locales
const DEFAULT_ES_LOCALE = LOCALES.SPANISH_MX;

//`value` is mapped with any server communications
//`shortCode` is used for short code display
//`label` is used for dropdown text display
const localeOptions = [
  {
    value: LOCALES.ENGLISH,
    shortCode: 'en',
    label: 'English (US)'
  },
  {
    value: LOCALES.SPANISH_MX,
    shortCode: ES_SHORT_CODE_LOCALE,
    label: 'Español (LATAM)'
  },
  {
    value: LOCALES.PORTUGUESE_BR,
    shortCode: 'pt-br',
    label: 'Português (BR)'
  },
  {
    value: LOCALES.JAPANESE,
    shortCode: 'ja',
    label: '日本語 (JP)',
    isDisabled: !config.isJapaneseEnabled
  }
];

const getSupportedLocaleOptionsFromModule = () => {
  return localeOptions.filter((locale) => !locale.isDisabled);
};

const NEXT_LOCALE_KEY = 'NEXT_LOCALE';
const NO_LOCALE_PATHS = [
  '/es-co/pricing',
  '/top-communities',
  '/about-us',
  'nas-academy/challenges'
];

const ALLOWED_DATE_TIME_FORMATS = [
  'dd MMM',
  'dd MMM yyyy',
  'dd MMM yyyy, hh:mm aaa',
  'eee, dd MMM',
  'eee, dd MMM yyyy',
  'eee, dd MMM yyyy, hh:mm aaa',
  'hh:mm aaa',
  'MMMM',
  'MMMM yyyy',
  'MMM yyyy',
  'dd/MM/yyyy'
];

const LOCALIZED_DATE_TIME_FORMAT = {
  [LOCALES.JAPANESE]: {
    'dd MMM': 'MMMMdo',
    'dd MMM yyyy': 'yyyy年MMMMdo',
    'dd MMM yyyy, hh:mm aaa': 'yyyy年MMMMdo HH:mm',
    'eee, dd MMM': 'MMMMdo (eee)',
    'eee, dd MMM yyyy': 'yyyy年MMMMdo (eee)',
    'eee, dd MMM yyyy, hh:mm aaa': 'yyyy年MMMMdo (eee) HH:mm',
    'hh:mm aaa': 'HH:mm',
    MMMM: 'MMMM',
    'MMMM yyyy': 'yyyy年MMMM',
    'MMM yyyy': 'yyyy年MMM',
    'dd/MM/yyyy': 'yyyy/MM/dd'
  }
};

module.exports = {
  LOCALES,
  localeOptions,
  defaultLocale,
  NEXT_LOCALE_KEY,
  NO_LOCALE_PATHS,
  DEFAULT_ES_LOCALE,
  ES_SHORT_CODE_LOCALE,
  getSupportedLocaleOptionsFromModule,
  ALLOWED_DATE_TIME_FORMATS,
  LOCALIZED_DATE_TIME_FORMAT
};
