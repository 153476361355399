import {
  initializeTrackUserId,
  storeTrackingInformation,
  trackGAEvent,
  trackTikTokIdentity
} from '@/utility/analytics';
import { createContext, useContext, useEffect, useState } from 'react';
import userService, {
  getTokensService,
  getUserProfile,
  updateUserProfile,
  userLoginService,
  validateEmailOtp
} from '@/services/userService';

import Auth from '@/modules/Auth';
import config from '@/utility/config';
import { getCommunitiesList, signUp } from '@/services/communitiesService';
import { usePathname, useRouter } from 'next/navigation';
import { LOGOUT_EVENT } from '@/utility/analyticsConsts';
import {
  communityAdminRolesArray,
  communityUserRoles
} from '@/utility/loginConstants';
import useLocale from '@/hooks/useLocale';
import {
  CM_PORTAL_HOMEPAGE_ROUTE,
  MEMBER_PORTAL_BASED_PATH,
  getMemberCommunityPageRoute
} from '@/utility/routesHelper';
import { COMMUNITY_ID_KEY } from '@/utility/constants';
import { showErrorToast } from '@/components/common/ToastContainer';
import sessionStorageService from '@/utility/sessionStorageService';
import { communitySubscriptionStatus } from '@/data/communities';
import useQueryParams from '@/hooks/useQueryParams';
import { getSentryModule } from '@/utility/sentryService';
import { convertObjectToQueryString } from '@/services/helpers/queryString';
import useShallowRouterReplace from '@/hooks/router/useShallowRouterReplace';
import CookieService from '@/utility/cookieService';

export const AuthContext = createContext({
  isLoggedIn: false,
  user: null
});
const { appleRedirectLink } = config;

export const AuthContextProvider = ({ children, userData }) => {
  const [accessToken, setAccessToken] = useState('');
  const [refreshToken, setRefreshToken] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [loginError, setLoginError] = useState('');
  const [isSocialLogin, setIsSocialLogin] = useState(false);
  const [socialLoginError, setSocialLoginError] = useState('');
  const [user, setUser] = useState(userData ? { ...userData } : null);
  const [openLoginModal, setOpenLoginModal] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isIntentionalLogout, setIsIntentionalLogout] = useState(false);
  const [userHaveNoCommunities, setUserHaveNoCommunities] =
    useState(false);

  const isDemoUser = !!user?.isDemo;

  const router = useRouter();
  const pathname = usePathname();
  const { replaceRoute } = useShallowRouterReplace();

  const { currentRouterQuery } = useQueryParams();

  const { currentLocale } = useLocale();

  const isMemberView = pathname?.startsWith(MEMBER_PORTAL_BASED_PATH);

  const openMemberView = () => {
    router.push(MEMBER_PORTAL_BASED_PATH);
  };

  const openManagerView = () => {
    router.push(CM_PORTAL_HOMEPAGE_ROUTE);
  };

  const {
    accessToken: queryAccessToken,
    refreshToken: queryRefreshToken
  } = currentRouterQuery || {};

  const checkIfUserIsOnlyMemberByRoles = (roles) => {
    //assume that if there is no roles, this user is a manager
    if (!roles) {
      return false;
    }

    return (
      !roles.includes(communityUserRoles.OWNER) &&
      !roles.includes(communityUserRoles.ADMIN) &&
      !roles.includes(communityUserRoles.MANAGER)
    );
  };

  const getUserData = async (accessToken) => {
    const paramActiveCommunityId = currentRouterQuery?.activeCommunityId;
    const storedCommunityId = CookieService.get(COMMUNITY_ID_KEY);
    const activeCommunityId = paramActiveCommunityId || storedCommunityId;
    setIsLoading(true);
    const params = activeCommunityId
      ? { activeCommunityId: activeCommunityId }
      : null;
    const { data, error } = await getUserProfile(accessToken, params);

    if (error || !data) {
      return { error: error, data: null };
    }

    const isMember = checkIfUserIsOnlyMemberByRoles(
      data.activeCommunityRoles
    );

    //the redirection has some delay, so we need to wait for a bit before setting the user
    const setUserState = isMember
      ? () => setTimeout(() => setUser(data), 1000)
      : () => setUser(data);

    setUserState();

    initializeTrackUserId(data?.user_id);

    //keeping track of session login so that this event does not get called more than once
    if (!Auth.getUserLoggedInForSession()) {
      trackTikTokIdentity({
        email: data?.email,
        phone: data?.learner?.phoneNumber
      });
      Auth.setUserLoggedInForSession();
    }

    Auth.setUserDataSession(data);

    setIsLoading(false);

    return { data };
  };

  const updatePassword = async ({
    email,
    password,
    passwordToken,
    callback
  }) => {
    setIsLoading(true);
    setLoginError('');
    const res = await userService.updatePassword({
      email,
      password,
      passwordToken
    });

    if (res.error) {
      setLoginError(res.error);
      setIsLoading(false);
      callback && callback(res);
      return;
    }

    const { token, refreshToken, user } = res.data;

    setUser(user);
    setRefreshToken(refreshToken);
    setAccessToken(token);
    Auth.authenticateUser(token, refreshToken);

    setIsLoading(false);

    callback && callback(res);
  };

  const setUserTokens = (accessToken, refreshToken) => {
    Auth.authenticateUser(accessToken, refreshToken);
  };

  const redirectMemberToCommunityPage = async (
    { tab = '', queryParams = {}, isFullReload } = {
      tab: '',
      queryParams: {},
      isFullReload: false
    }
  ) => {
    const storedCommunityId = CookieService.get(COMMUNITY_ID_KEY);
    const { data: memberCommunitiesData, error: memberCommunitiesError } =
      await getCommunitiesList({
        //if we don't have any default data for community, we just use the current active community data
        activeCommunityId: storedCommunityId || ''
      });

    if (memberCommunitiesError) {
      return false;
    }

    // handle if user have no communities at all
    if (!memberCommunitiesData?.communities?.length) {
      setUserHaveNoCommunities(true);
      return;
    }
    const activeCommunity =
      memberCommunitiesData?.activeCommunity?.[0] ||
      memberCommunitiesData?.communities?.[0];

    if (activeCommunity) {
      // check if the users subscription is pending for approval.
      const isPendingApproval = !!activeCommunity?.subscriptions?.find(
        (communityInfo) =>
          communityInfo?.status === communitySubscriptionStatus.pending
      );
      const communitySlug = activeCommunity.link; // community slug with a "/" prefix
      const communityPageRoute = isPendingApproval // If user is pending application approval in community then do not need to redirect to member experience.
        ? communitySlug
        : tab // check if there is a specific tab to redirect to
          ? getMemberCommunityPageRoute(communitySlug, tab)
          : getMemberCommunityPageRoute(communitySlug);

      // Mainly for discord variant communities
      const queryStr = convertObjectToQueryString(queryParams);
      const communityPageFullUrl = queryStr
        ? `${communityPageRoute}?${queryStr}`
        : communityPageRoute;
      if (isFullReload) {
        window.location.href = communityPageFullUrl;
      } else {
        router.push(communityPageFullUrl);
      }
    }
  };

  const authenticateUser = async ({ accessToken, refreshToken }) => {
    await getUserData(accessToken);
    Auth.authenticateUser(accessToken, refreshToken);
  };

  // Standard email, password Login
  const login = async (
    email,
    password,
    callback,
    communityMandatory = false
  ) => {
    setIsLoading(true);
    setLoginError('');
    const res = await userLoginService(
      email,
      password,
      communityMandatory // To prevent users with no community to login
    );

    if (res.error) {
      setLoginError(res.error);
      setIsLoading(false);
      callback && callback(res);
      return res;
    }

    const { token, refresh_token } = res.data;

    authenticateUser({ accessToken: token, refreshToken: refresh_token });

    setIsLoading(false);

    callback?.(res);
    return res;
  };

  const loginWithQueryParmTokens = async (token, refresh_token) => {
    setIsLoading(true);
    setLoginError('');
    setRefreshToken(refresh_token);
    Auth.authenticateUser(token, refresh_token);
    setAccessToken(token);
    delete currentRouterQuery.accessToken;
    delete currentRouterQuery.refreshToken;
    replaceRoute(currentRouterQuery);

    setIsLoading(false);
  };

  useEffect(() => {
    if (queryAccessToken && queryRefreshToken) {
      loginWithQueryParmTokens(queryAccessToken, queryRefreshToken);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryAccessToken, queryRefreshToken]);

  const updateUserData = async (payload) => {
    try {
      const res = await updateUserProfile(payload);
      const { error } = res;

      if (error) {
        showErrorToast(error);
        return;
      }

      // refresh data
      getUserData();

      return res;
    } catch (e) {
      return { error: e.message };
    }
  };

  const signup = async (formData) => {
    setIsLoading(true);
    setLoginError(null);
    try {
      const res = await signUp({
        ...formData,
        languagePreference: currentLocale
      });
      const { data, error } = res;
      if (error) {
        setLoginError(error);
        setIsLoading(false);
        return res;
      }
      const { token, refreshToken } = data;

      await authenticateUser({
        accessToken: token,
        refreshToken: refreshToken
      });
      setIsLoading(false);
      return res;
    } catch (e) {
      const error = e.message;
      setLoginError(error);
      setIsLoading(false);
      return { error };
    }
  };

  useEffect(() => {
    const fetchUserDataAsync = async () => {
      const { error } = await getUserData();

      if (error) {
        showErrorToast(error);
        logout();
      }
    };

    if (accessToken) {
      fetchUserDataAsync();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken]);

  // Signup new member with default password
  const signupNewMemWithDefPwd = async ({
    email,
    phoneNumber = '',
    token
  }) => {
    return await signup({
      email,
      token,
      phoneNumber,
      isCommunityAdmin: false,
      setDefaultPassword: true
    });
  };

  const loginWithOtpValidation = async ({ email, otpToken, otp }) => {
    const response = await validateEmailOtp({
      otpToken,
      email,
      otp
    });

    const { data } = response;

    if (!data) return response;

    const { token, refreshToken } = data;

    await authenticateUser({
      accessToken: token,
      refreshToken: refreshToken
    });

    return response;
  };

  /**
   * Use token received from social logins (authToken) or refresh token and pass to backend to get access token and refresh token
   * Payload should send either authToken or refreshToken
   * @param {*} tokenPayload | Object
   * @param {*} tokenPayload.authToken | String | For Social Logins
   * @param {*} tokenPayload.refreshToken | String
   */
  const getTokens = async (tokenPayload) => {
    setIsLoading(true);
    setSocialLoginError('');
    const { data, error } = await getTokensService(tokenPayload);
    setIsLoading(false);

    if (error) {
      return { error };
    }

    const { token, refresh_token } = data;
    //token or refresh_token is missing
    //this case ideally never happens
    if (!token || !refresh_token) {
      const customError = 'Error in logging in. Please try again.';
      return { error: customError };
    }
    setIsSocialLogin(true);

    setRefreshToken(refresh_token);
    setAccessToken(token);

    return { accessToken: token, refreshToken: refresh_token };
  };

  const handleSocialAuth = async (response, provider = 'google') => {
    let authToken = '';
    let code = '';
    let appleUserData = null;
    let payload = {};

    switch (provider) {
      case 'facebook':
        const fbToken = response?.accessToken;
        authToken = fbToken;
        payload = { authToken, provider };
        break;
      case 'google':
        authToken = response?.code;
        payload = { code: authToken, provider };
        break;
      case 'apple':
        code = response?.authorization?.code;
        authToken = response?.authorization?.id_token;
        appleUserData = response?.user;
        payload = {
          code,
          authToken,
          provider,
          appleUserData,
          appleRedirectUrl: appleRedirectLink
        };
        break;
      default:
    }

    if (authToken) {
      const {
        accessToken,
        refreshToken,
        error: tokenError
      } = await getTokens(payload);

      if (tokenError) {
        setSocialLoginError(tokenError);
        return {
          data: null,
          error: tokenError
        };
      }

      Auth.authenticateUser(accessToken, refreshToken);
      const { data, error } = await getUserData();
      return {
        data: {
          user: data
        },
        error
      };
    }
    return { data: null, error: 'Cannot authenticate with your token' }; //TODO: This error need to be captured from social logins themselves
  };

  const loginWithToken = (accessToken, refreshToken) => {
    Auth.authenticateUser(accessToken, refreshToken);
    setRefreshToken(refreshToken);
    setAccessToken(accessToken);
    getUserData();
  };

  const logout = (
    redirect = true,
    isIntended = false,
    redirectionLink = ''
  ) => {
    trackGAEvent(LOGOUT_EVENT, { email: user?.email });
    setIsIntentionalLogout(isIntended);
    Auth.deauthenticateUser(redirect, () => {
      router.push(redirectionLink ? redirectionLink : '/login');
    });
    setUser(null);
    CookieService.delete(COMMUNITY_ID_KEY);
    sessionStorageService.clear();
  };

  const toggleLoginModal = () => setOpenLoginModal((open) => !open);

  useEffect(() => {
    if (user) setOpenLoginModal(false);
  }, [user]);

  useEffect(() => {
    const initialAccessToken = Auth.getUserTokenFromCookies() || '';
    const initialRefreshToken = Auth.getRefreshTokenFromCookies() || '';

    setAccessToken(initialAccessToken);
    setRefreshToken(initialRefreshToken);
  }, [user]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      storeTrackingInformation(currentRouterQuery);
    }
  }, [currentRouterQuery]);

  useEffect(() => {
    if (!user) return;

    const userRolesData = user?.roles ? Object.keys(user.roles) : [];
    const isUserAdmin = userRolesData?.some((role) =>
      communityAdminRolesArray.includes(role)
    );

    setIsAdmin(isUserAdmin);
  }, [user]);

  // Tag user in sentry
  useEffect(() => {
    if (!user) return;

    const { email, _id: id } = user;
    if (email && id) {
      (async () => {
        const sentry = await getSentryModule();
        sentry.setUser({ email, id });
      })();
    }
  }, [user]);

  const updateUserLocale = async (selectedLocale) => {
    return await updateUserProfile({
      languagePreference: selectedLocale
    });
  };

  const updateUserState = (user) => {
    setUser(user);
  };

  const value = {
    updatePassword,
    login,
    loginWithToken,
    loginWithQueryParmTokens,
    loginWithOtpValidation,
    handleSocialAuth,
    logout,
    signup,
    signupNewMemWithDefPwd,
    loginError,
    setLoginError,
    socialLoginError,
    resetLoginError: () => setLoginError(''),
    isDemoUser,
    accessToken,
    refreshToken,
    isLoggedIn: Boolean(user),
    user,
    getUserData,
    isLoading,
    redirectMemberToCommunityPage,

    toggleLoginModal,
    openLoginModal,

    isUserLoggedIn: Boolean(user), //legacy one, so we need to keep it
    hasToken: !!Auth.getUserToken(),
    setUserTokens,
    isAdmin,
    authenticateUser,
    isSocialLogin,
    updateUserLocale,
    updateUserData,
    openMemberView,
    openManagerView,
    isMemberView: isMemberView,
    updateUserState,
    isIntentionalLogout,

    userHaveNoCommunities
  };

  return (
    <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
  );
};

export const useAuthContext = () => useContext(AuthContext);
