import { trackGAEventWithCommunityAndUser } from '@/utility/analytics';
import classNames from 'classnames';
import React from 'react';
import Icon from './../Icon';
import type { IconName, IconPath } from './../Icon';
import { addSentryBreadcrumb } from '@/utility/sentryService';
import Link from 'next/link';
import type { NPLButtonSizeType, NPLButtonHierarchyType } from './types';
import {
  getButtonSize,
  getIconSizeByButtonSize,
  getTextStyleByButtonSize
} from './utils';
import NPLIconButton from '../NPLIconButton';
import { withTooltip } from '@/components/common/NplToolTip';
import { buttonStyles } from '../constants';
import withComponentClassName, {
  WithComponentClassName
} from '@/utility/withComponentClassName';

export type NPLButtonProps = WithComponentClassName<{
  hierarchy: NPLButtonHierarchyType;
  size: NPLButtonSizeType;
  darkMode?: boolean;
  buttonText?: string;
  leadIcon?: IconName | string;
  tailIcon?: IconName | string;
  disabled?: boolean;
  loading?: boolean;
  stretch?: boolean;
  rounded?: boolean;
  dataTestId?: string;
  iconWidth?: number;
  isSecondary?: boolean;
  multiply?: boolean;
  tooltipText?: string;
  onClick?: (
    e:
      | React.MouseEvent<HTMLButtonElement>
      | React.MouseEvent<HTMLAnchorElement>
  ) => void;
  leadIconType?: IconPath;
  tailIconType?: IconPath;
  [key: string]: unknown; // for additional props
  link?: string;
}>;

const NPLButton: React.FC<NPLButtonProps> = ({
  hierarchy = 'accent_primary',
  size = 'md',
  darkMode = false,
  buttonText = '',
  leadIcon,
  tailIcon,
  disabled = false,
  loading = false,
  stretch = false,
  dataTestId = '',
  rounded = false, // set to true for icon only button
  multiply = false, // set to true if button is on a background
  //isSecondary = false, //TODO: @Nick commented out this for now, we'll use new hierarchy prop later
  onClick,
  leadIconType = 'common', // for NPLIcon lead icon type (common, social, payment_methods)
  tailIconType = 'common', // for NPLIcon lead icon type (common, social, payment_methods)
  tooltipText,
  link,
  iconWidth,
  componentClassName,
  ...btnProps // for data-test-id
}) => {
  if (rounded && (leadIcon || tailIcon)) {
    return (
      <NPLIconButton
        {...btnProps}
        hierarchy={hierarchy}
        size={size}
        darkMode={darkMode}
        disabled={disabled}
        icon={leadIcon || tailIcon}
        onClick={onClick}
        tooltip={tooltipText}
        link={link}
      />
    );
  }

  const sizeClassName = getButtonSize(size, hierarchy === 'plain');
  const colorMode = darkMode ? 'dark' : 'light';
  const disabledMode = disabled ? 'disabled' : 'enabled';
  const populatedColorStyles =
    buttonStyles[hierarchy][colorMode][disabledMode];

  const getClassNames = () => {
    let btnClassNames = classNames(
      componentClassName,
      `relative flex justify-center items-center rounded-100`,
      {
        'mix-blend-multiply': multiply,
        'w-full': stretch
      }
    );

    btnClassNames = classNames(
      btnClassNames,
      sizeClassName,
      populatedColorStyles.background
    );

    return btnClassNames;
  };

  const renderIcon = (iconName: IconName | string, iconPath: IconPath) => {
    if (!iconName) return null;

    const iconSize = getIconSizeByButtonSize(size);
    const fillColorClassName = populatedColorStyles.content;

    return (
      <Icon
        key={iconName}
        name={iconName as IconName}
        width={iconWidth || iconSize}
        height={iconSize}
        className={fillColorClassName}
        path={iconPath}
      />
    );
  };

  const btnClassNames = getClassNames();

  const handleButtonClick = (
    e:
      | React.MouseEvent<HTMLButtonElement>
      | React.MouseEvent<HTMLAnchorElement>
  ) => {
    // track click event
    const isExtra = true;
    trackGAEventWithCommunityAndUser(
      'npl-button-clicked',
      {
        buttonProps: btnProps
      },
      isExtra
    );

    addSentryBreadcrumb(
      `NPLButton clicked: ${buttonText} ${
        dataTestId ? ` | data-test-id: (${dataTestId})` : ''
      }`
    );

    // pass on to onClick prop
    if (onClick) {
      onClick(e);
    }
  };

  const element = link ? Link : 'button';

  // Common props
  const commonProps = {
    className: btnClassNames,
    onClick: handleButtonClick
  };

  const buttonSpecificProps = {
    type: 'button',
    'data-test-id':
      dataTestId !== '' ? dataTestId : buttonText || 'npl-button',
    disabled: disabled || loading,
    href: null,
    ...btnProps
  };

  // Combine props based on the element type
  const elementProps = { ...commonProps, ...buttonSpecificProps };

  if (link) {
    elementProps.href = link;
  }

  // Ensure the props align with the element type
  // Without this, typescript is unsure if href is actually passed to Link component
  const Element = element as React.ElementType;

  const buttonElement = React.createElement(
    Element,
    elementProps,
    <>
      <div
        className={classNames(
          'min-h-16 flex min-w-[16px] items-center justify-center',
          size === 'xs' ? 'space-x-2' : 'space-x-4',
          {
            'opacity-0': loading
          }
        )}>
        {renderIcon(leadIcon, leadIconType)}
        {buttonText && (
          <span
            className={classNames(
              getTextStyleByButtonSize(size),
              populatedColorStyles.content,
              'w-max'
            )}>
            {buttonText}
          </span>
        )}
        {renderIcon(tailIcon, tailIconType)}
      </div>
      {loading && (
        <div className="absolute top-0 left-0 flex items-center justify-center w-full h-full rounded-100">
          <div className="animate-[spin_2s_linear_infinite] w-[17px] h-[17px]">
            <Icon
              name="spinning-wheel"
              width={20}
              height={20}
              fillOpacity={0.5}
              className={populatedColorStyles.content}
            />
          </div>
        </div>
      )}
    </>
  );

  if (tooltipText) {
    return withTooltip(tooltipText, buttonElement);
  }

  return buttonElement;
};

export default withComponentClassName(NPLButton, false);
